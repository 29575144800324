<template>
  <div :style="playerContStyle" id="io-player-container">
    <v-stage
      :style="`cursor: ${chosen_object ? 'copy' : 'default'}; display: block; position: absolute; z-index: 1; touch-action: none;`"
      v-if="layerObjects.length || (chosen_object && isEditor)"
      ref="stage"
      :config="configStage"
      @pointerdown="handleStageMouseDown"
      @pointerup="handleStageMouseUp"
      @pointermove="handleStageDrag"
    >
      <v-layer>
        <v-ellipse
          v-for="item in layerObjects.filter(v => v.type === 'ellipse')"
          :key="item.name"
          :config="item"
          @transformend="handleTransformEnd"
          @dragend="handleTransformEnd"
        ></v-ellipse>
        <v-line
          v-for="item in layerObjects.filter(v => v.type === 'line')"
          :key="item.name"
          :config="item"
          @transformend="handleTransformEnd"
          @dragend="handleTransformEnd"
        ></v-line>
        <v-line
          v-for="item in layerObjects.filter(v => v.type === 'free')"
          :key="item.name"
          :config="item"
          @transformend="handleTransformEnd"
          @dragend="handleTransformEnd"
        ></v-line>
        <v-arrow
          v-for="item in layerObjects.filter(v => v.type === 'arrow')"
          :key="item.name"
          :config="item"
          @transformend="handleTransformEnd"
          @dragend="handleTransformEnd"
        ></v-arrow>
        <v-rect
          v-for="item in layerObjects.filter(v => v.type === 'rectangle')"
          :key="item.name"
          :config="item"
          @transformend="handleTransformEnd"
          @transform="handleTransform"
          @dragend="handleTransformEnd"
        ></v-rect>
        <v-text
          v-for="item in layerObjects.filter(v => v.type === 'text')"
          :key="item.name"
          :config="item"
          @transformend="handleTransformEnd"
          @transform="handleTransform"
          @dragend="handleTransformEnd"
        ></v-text>
        <v-transformer ref="transformer" />
      </v-layer>
    </v-stage>
    <Player
      :class="{'video-player-container': isDarkModeEnabled, 'video-player-container-dark': !isDarkModeEnabled}"
      :options="options"
    ></Player>
  </div>
</template>

<script>
import Player from "../Player.vue"
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid';
import graphics from '@/mixins/graphics.js'

export default {
  components: { Player },
  props: ['options', 'value', 'color', 'isEditor', 'mutable'],
  mixins: [ graphics ],
  data: () => ({
    isDarkModeEnabled: window.localStorage.getItem('tiimio-dark') != 'true', // keep
    creating_object_name: null,
    observer: null,
    isDrawing: false,
  }),
  shortcuts: {
    keydown: function (e) {
      if(e.code == 'Backspace') {
        this.removeFocusedShape()
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateScale)

    this.$nextTick(() => {
      this.updateScale()
    })

    setTimeout(() => {
      this.updateScale()
    }, 400)
  },
  beforeDestroy() {
    if(!this.$route.path.includes('editor'))this.clear()
    window.removeEventListener('resize', this.updateScale)
  },
  methods: {
    ...mapActions('draw', [
      'removePauseTimeout',
      'removeObject',
      'clear',
    ]),
    ...mapMutations([
      'DISABLE_KEYBINDS',
      'ENABLE_KEYBINDS'
    ]),
    ...mapMutations('draw', [
      'SET_SELECTED_SHAPE_NAME',
      'REMOVE_OBJECT',
      'SET_CHOSEN_OBJECT',
      'EDIT_OBJECT',
      'SET_NEW_TEXT',
      'SET_OBJECTS',
      'SET_SCALE',
      'UPDATE_SCALE'
    ]),
    exportStage() {
      let stage = this.$refs.stage.getNode()
      let dataURL = stage.toDataURL()

      alert(dataURL)

      return dataURL
    },
    exportFrame() {
  // Directly select the single video element on the page
  const videoElement = document.querySelector('video');

  // Define the output dimensions
  const outputHeight = 1080;
  const outputWidth = (outputHeight * 16) / 9;

  const canvas = document.createElement('canvas');
  canvas.width = outputWidth;
  canvas.height = outputHeight;
  const context = canvas.getContext('2d');

  // Calculate the scaling factor and draw the video frame
  const scaleFactor = Math.min(canvas.width / videoElement.videoWidth, canvas.height / videoElement.videoHeight);
  const scaledWidth = videoElement.videoWidth * scaleFactor;
  const scaledHeight = videoElement.videoHeight * scaleFactor;
  const offsetX = (canvas.width - scaledWidth) / 2;
  const offsetY = (canvas.height - scaledHeight) / 2;

  context.drawImage(videoElement, offsetX, offsetY, scaledWidth, scaledHeight);

  // Assume we have access to the Konva stage via this.$refs.stage
  // First, convert the Konva stage to a canvas
  const konvaCanvas = this.$refs.stage.getStage().toCanvas({
    width: videoElement.videoWidth,
    height: videoElement.videoHeight,
    pixelRatio: 1, // Adjust as needed
  });

  // Then, draw the Konva canvas onto the main canvas
  context.drawImage(konvaCanvas, offsetX, offsetY, scaledWidth, scaledHeight);

  // Export the final composition as an image
  const imageDataUrl = canvas.toDataURL('image/jpeg', 1.0);
  window.open(imageDataUrl);
    },
    getPointerPos() {
      const stage = this.$refs.stage.getStage()
      const { x, y } = stage.getPointerPosition()
      return {
        x: x * 1 / this.scale,
        y: y * 1 / this.scale
      }
    },
    handleStageMouseDown(e) {
      // check if an object was clicked
      if (e.target === e.target.getStage()) {
        this.SET_SELECTED_SHAPE_NAME('');
        this.updateTransformer();
      }

      if(this.chosen_object) {
        let undraggables = [...this.objects]?.map(v => {
          return {
            ...v,
            draggable: false
          }
        })
        this.SET_OBJECTS(undraggables)

        this.$nextTick(() => {
          const po = this.getPointerPos()

          const { x, y } = po
          let object;

          switch(this.chosen_object) {
            case 'line':
              object = this.formLine(x, y)
              break;
            case 'ellipse':
              object = this.formEllipse(x, y)
              break;
            case 'arrow':
              object = this.formArrow(x, y)
              break;
            case 'text':
              object = this.formText(x, y)
              break;
            case 'free':
              this.isDrawing = true
              object = this.formLine(x, y)
          }
          this.$emit('shapeAdded', object)
        })

        return
      }

      // clicked on transformer - do nothing
      if(e.target.getParent()?.className === 'Transformer') return;

      // find clicked item by its name
      const name = e.target.name();
      const rect = this.objects.find((r) => r.name === name);
      if (rect) {
        this.SET_SELECTED_SHAPE_NAME(name);
      } else {
        this.SET_SELECTED_SHAPE_NAME('');
      }

      this.updateTransformer();

      this.$emit('action');
    },
    handleStageMouseUp() {
      this.isDrawing = false;
      this.creating_object_name = null;
      let draggables = [...this.objects].map(v => {
        return {
          ...v,
          draggable: true
        }
      })
      this.SET_OBJECTS(draggables)

      if(this.chosen_object == 'text') this.SET_CHOSEN_OBJECT(null)
    },
    removeFocusedShape() {
      let chosen_name = this.selected_shape_name
      if(!chosen_name) return
      this.removeObject(chosen_name)

      this.SET_SELECTED_SHAPE_NAME('')
      this.updateTransformer()
    },
    handleStageDrag() {
      if(!this.chosen_object) return
      if(!this.creating_object_name) return

      const po = this.getPointerPos()
      const { x, y } = po

      switch(this.creatingObjectType) {
        case 'line':
          this.editLine(this.creating_object_name, x, y)
          break;
        case 'ellipse':
          this.editEllipse(this.creating_object_name, x, y)
          break;
        case 'arrow':
          this.editLine(this.creating_object_name, x, y)
          break;
        case 'free':
          this.editLine(this.creating_object_name, x, y)
          break
      }

      // let mod = [...this.objects].concat({
      //   let { top, left } = document.getElementById('cont').getBoundingClientRect()
      // })
    },
    updateScale() {
      this.UPDATE_SCALE();
    },
    updateTransformer() {
      if(!this.mutable) return
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage()
      const selectedNode = stage.findOne('.' + this.selected_shape_name);

      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return;
      }

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode]);
      } else {
        // remove transformer
        transformerNode.nodes([]);
      }

      if(selectedNode?.attrs?.type == 'text') {
        transformerNode.boundBoxFunc((_, newBox) => {
          newBox.width = Math.max(30, newBox.width)
          return newBox;
        })

        // transformerNode.enabledAnchors(['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right']);
        transformerNode.enabledAnchors(['middle-right', 'middle-left']);
      } else {
        transformerNode.boundBoxFunc(() => {})
        transformerNode.enabledAnchors(['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right']);
      }
    },
    handleTransform(e) {
      let item = e.target?.attrs

      if(!item) return

      let width;
      if(!item.width) {
        width = Math.abs(item.x - this.getPointerPos().x)
      } else {
        width = item.width
      }

      item = {
        ...item,
        width: width * item.scaleX,
        scaleX: 1,
        scaleY: 1
      }

      e.target.scaleX(1)
      e.target.scaleY(1)

      this.EDIT_OBJECT(item)
      this.updateTransformer()
    },
    handleTransformEnd(e) {
      // shape is transformed, let us save new attrs back to the node
      // find element in our state
      const item = [...this.objects].find(
        (r) => r.name === this.selected_shape_name
      );

      if(!item) return

      // update the state
      item.x = e.target.x();
      item.y = e.target.y();
      item.rotation = e.target.rotation();
      item.scaleX = e.target.scaleX();
      item.scaleY = e.target.scaleY();

      // if(item.type == 'text') {

      // }

      let mod = [...this.objects].filter(v => v.name !== this.selected_shape_name)
      mod = mod.concat(item)
      this.SET_OBJECTS(mod)
    },

    /* */
    /* DRAWING OF SHAPES */
    /* */

    editLine(id, x, y) {
      let ob = [...this.objects].find(v => v.name === id)
      if(!ob) return
      if(this.isDrawing) ob['points'] = [...ob['points'], x, y]
      else ob['points'] = [ob['points'][0], ob['points'][1], x, y]

      this.SET_OBJECTS([...this.objects].filter(v => v.name !== id).concat(ob))
    },
    editEllipse(id, x, y) {
      let ob = [...this.objects].find(v => v.name === id)
      if(!ob) return

      ob['radiusX'] = Math.abs(ob['x'] - x)
      ob['radiusY'] = Math.abs(ob['y'] - y)

      this.SET_OBJECTS([...this.objects].filter(v => v.name !== id).concat(ob))
    },
    objectBaseData() {
      const id = uuidv4()
      this.creating_object_name = id;

      return {
        video_width: this.player_container_size.width,
        layer_id: this.layer_id,
        name: id,
        draggable: true,
        shadowBlur: 5,
        opacity: 1,
      }
    },
    formLine(x, y) {
      const ob = {
        ...this.objectBaseData(),
        type: 'line',
        points: [x, y, x +1, y + 1],
        stroke: this.color,
        strokeWidth: this.new_stroke_width,
        lineCap: 'round',
      }
      if(this.new_style == 'dashed') {
        ob.dash = this.dashed_style
      }
      if(this.isDrawing) {
        ob.type = 'free'
        ob.lineJoin = 'round'
      }
      this.SET_OBJECTS([...this.objects].concat(ob))

      return ob
    },
    formText(x, y) {
      const ob = {
        ...this.objectBaseData(),
        shadowBlur: 0,
        text: this.new_text,
        type: 'text',
        scaleY: 1,
        stroke: "#ffffff",
        fill: "#000000",
        strokeWidth: 0,
        fontSize: this.new_font_size,
        height: 'auto',
        x,
        y
      }

      // const background = {
      //   ...this.objectBaseData(),
      //   type: 'rectangle',
      //   height: this.new_font_size,
      //   width: 100,
      //   fill: "#ffffff",
      //   cornerRadius: 5,
      //   textName: ob.name,
      //   x,
      //   y,
      // }

      this.SET_OBJECTS([...this.objects]
        .concat(ob))
        // .concat(background)

      this.SET_SELECTED_SHAPE_NAME(ob.name)

      this.$nextTick(() => {
        this.updateTransformer()
      })

      this.SET_NEW_TEXT('New text')

      if(this.chosen_object == 'text') this.SET_CHOSEN_OBJECT(null)

      return [
        // background,
        ob
      ]
    },
    formArrow(x, y) {
      const ob = {
        ...this.objectBaseData(),
        type: 'arrow',
        points: [x, y, x +1, y + 1],
        pointerLength: 10,
        pointerWidth: 10,
        stroke: this.color,
        strokeWidth: this.new_stroke_width,
        lineCap: 'round'
      }
      if(this.new_style == 'dashed') {
        ob.dash = this.dashed_style
      }

      this.SET_OBJECTS([...this.objects].concat(ob))

      return ob
    },
    formEllipse(x, y) {
      const ob = {
        ...this.objectBaseData(),
        type: 'ellipse',
        x,
        y,
        radiusX: 5,
        radiusY: 5,
        stroke: this.color,
        strokeWidth: this.new_stroke_width,
        lineCap: 'round',
        fillEnabled: this.new_fill_enabled,
        fill: ''
      }
      if(this.new_style == 'dashed') {
        ob.dash = this.dashed_style
      }

      this.SET_OBJECTS([...this.objects].concat(ob))

      return ob
    },
  },
  computed: {
    ...mapGetters('draw', [
      'selected_shape_name',
      'objects',
      'original_video_width',
      'pause_timeout',
      'chosen_object',
      'player_container_size',
      'scale',
      'layer_id',
      'canvas_dimensions_key',

      'new_color',
      'new_stroke_width',
      'new_fill_enabled',
      'new_fill_color',
      'new_opacity',
      'new_font_size',
      'new_text',
      'new_font_size',
      'new_style'
    ]),
    ...mapGetters('player', [
      'player'
    ]),
    dashed_style() {
      return [(this.new_stroke_width/2 + 4), (1.8 * this.new_stroke_width + 1)]
    },
    playerContStyle() {
      this.canvas_dimensions_key;
      const element = document.getElementById('io-player-container')
      if(!element) return

      const parent = element.parentElement
      const parent_width = parent.offsetWidth
      const parent_height = parent.offsetHeight

      if(parent_width * 9 < parent_height * 16) {
        return {
          width: '100%',
          aspectRatio: '16/9'
        }
      } else {
        return {
          height: '100%',
          aspectRatio: '16/9'
        }
      }
    },
    configStage() {
      // calculate the ratio of the original video width of the graphics and current and scale the graphics accordingly
      this.canvas_dimensions_key;
      this.player;

      if(!document.getElementById('io-player')) return

      const player = document.getElementById('io-player')
      const width = player.offsetWidth
      const height = player.offsetHeight

      let scale
      if(!this.original_video_width) {
        scale = 1
      } else {
        scale = width / this.original_video_width
      }

      this.SET_SCALE(scale)
      return {
        height,
        width,
        // ...this.dimensions,
        scaleX: scale,
        scaleY: scale
      }
    },
    layerObjects() {
      // return all of the objects in the layer handled with right scalings
      return this.objects?.filter(v => (v.layer_id == this.layer_id) && this.layer_id).map(o => {
        return {
          ...o,
          draggable: !this.mutable ? false : o.draggable
        }
      }) || []
        // .map(o => {
        //   const scale = o.video_width / this.player_container_size.width
        //   return {
        //     ...o,
        //     scaleX: scale,
        //     scaleY: scale
        //   }
        // })
    },
    creatingObjectType() {
      return this.objects.find(v => v.name === this.creating_object_name)?.type
    }
  },
  watch: {
    'selected_shape_name': {
      handler(oldValue, newValue) {
        if(oldValue != newValue) this.updateTransformer()
      }
    }
  }
}
</script>

<style>
  .video-player-container > div > div > div > video {
    background-color: white;
  }

  .video-player-container-dark > div > div > div > video {
    background-color: #1E1E1E;
  }
</style>